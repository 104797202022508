@import url(https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}







.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

:root{
  --themeclr: #fbbd18;
  --lightblue:#00bdfe;
}
html,body{
  font-family: 'Kanit', sans-serif !important;
  font-size: 14px;
}
a{
    text-decoration: none !important;
}
body{
  background-color: #000;
}
html,body {
    scroll-behavior: smooth;
  }

a {
    outline: none !important;

}



/* INNER PAGE CSS */
.get-started-btn
{
  background-color: transparent !important;
  /* border-radius: 30px !important; */
  border:1px solid #fbbd18 !important;
  border:1px solid var(--themeclr) !important;
  color: white;
  padding: 8px 25px;
    font-size: 14px !important;
    font-weight: 700;
}
.get-started-btn:hover
{
  background-image: linear-gradient(to bottom,#fbbd18 6%,#fbbd18 59%,#fbbd18);
  background-image: linear-gradient(to bottom,var(--themeclr) 6%,var(--themeclr) 59%,var(--themeclr));
    border: 1px solid #fbbd18 !important;
    border: 1px solid var(--themeclr) !important;
  color:#000000;

}

@media only screen and (max-width:575px){
  .ribbon {
    font-size: 12px;
    height: 20px !important;
    right: -38px !important;
    top: 13px !important;
  }
  .font_20
  {
font-size: 18px !important;
  }
  .coimg_soon_bg
  {
    background-size: cover,100% !important;
    background-position: top, center;
  }
  .coimg_soon_center img
  {
    max-width: 150px !important;
  }
  .logo_img_sm
  {
    max-width: 200px;
  }
  .whole_sec
  {
    /* padding-top: 200px !important; */
  }
  .nav_parnt
  {
    flex-direction: column;
  }
  .nav_parnt_2, .nav_parnt_1
  {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }
  .btn_row
  {
    flex-wrap: wrap !important;
  }
  .btn_row button
  {
    width: calc(50% - 1rem) !important;
  }
  .tab_div .nav-pills .nav-link
  {
    /* margin-right: 5px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    font-size: 14px !important; */
    margin-right: 1px !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
    font-size: 12px !important;
  }
  .header_flex
  {
    flex-direction: column;
  }
  .nav_parnt
  {
    /* margin-top: 20px !important; */
  }
  .inner_card
  {
background-size: cover !important;
  }





  .grid_view
  {
    grid-template-columns: 100% !important;
  }
  .grid_view .btn-purple-new
  {
    margin-top: 20px;
  }
}


.banner_title
{
  color:white;
  font-size: 35px;
  font-weight: 700;
  margin-bottom:0px !important;
}
.hr_yellow
{
  border-top: 1px solid #fbbd18 !important;
  border-top: 1px solid var(--themeclr) !important;
  width: 100% !important;
}
.banner_subtitle
{
  color:#433e33;
  font-size: 16px;
  font-weight: 500;

}








.flex_cen_col
{
  display: flex;
  justify-content: center;
  flex-direction: column;
}



@media only screen and (min-width:768px) and (max-width:1199px)
{
  .nav_parnt_2
  {
    display: flex;
    align-items: center;
  }
  .btn-purple
  {
padding-left: 25px !important;
padding-right: 25px !important;
  }
  .grid_view
  {
    grid-template-columns: 100% !important;
  }
  .grid_view .btn-purple
  {
    margin-top: 20px;
  }
  .grid_view .btn-purple-new
  {
    margin-top: 20px;
  }
}
.footer
{
  margin-top: -50px;
}
/* inner css */




.arro_change .fa-angle-down:before
{
content:"\f106";
}



/* Modal css */
.modal-dialog .modal-content
{
  
  background-color: #070707;
  /* border-radius: 20px !important; */
}
.modal-dialog .modal-header
{
background-color: #212121;
border:none !important;
padding-bottom: 1rem !important;
border-top-left-radius: 20px !important;
border-top-right-radius: 20px !important;
}

.sec-head 
{
  font-size: 16px !important;
  font-weight: 500;
  color:#fff;
  margin-bottom: 0px !important;
}
.modal-dialog .modal-header .close
{
color:#fbbd18 !important;
color:var(--themeclr) !important;
}
.wallet-lists ul li
{
  list-style-type: none !important;
}
.wallet-lists ul li:hover
{
  background-color: #212121 !important;
}
.wallet-lists li img {
  width: 30px;
}
.side-head-li
{
  font-size: 14px !important;
}
.wallet-lists ul
{
  padding-left: 0px !important;
}

.wallet-lists li {
  grid-gap: 10px;
  grid-gap: 10px;
  gap: 10px;
  padding: 10px;
  display: flex;
  align-items: baseline;
  margin: 20px 0 0;
  /* border-radius: 8px; */
  cursor: pointer;
}
.side-head-li
{
  color:#fff;
}
.wal-option {
  text-align: left;
}
.modal-header p
{
  font-size: 14px !important;
}

.input-group input
{
  background: transparent;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: none;
    border-radius: 0px;
color:#fff !important;

}

.input-group-text.doll
{
background-color: transparent !important;
border-top: none;
border-left: none;
border-right: none;
border-bottom: none;

border-radius: 0px;
color:#fff !important;

}

.btn-purple-new
{
  color:#fff !important;
}
.font_14
{
  font-size: 14px !important;
}

.input-groups input,.input-groups .input-group-text
{
  font-size: 14px !important;
}

.bord-top-none
{
border:none !important;
}
.doll-btn
{
  background-color: transparent !important;
  border:none !important;
}

.input-group input
{
  min-height: 42px !important;
}

.div_brn_grid
{
  display: grid !important;
  grid-template-columns: 49% 49%;
  grid-column-gap: 2%;
}

@media only screen and (max-width:767px)
{
  .footer
  {
    margin-top: -100px !important;
  }
}

@media only screen and (min-width:1200px)
{
  .nav_parnt_2
  {
    display: flex;
    align-items: center;
  }
  .container.container_custom
  {
    /* max-width: 90% !important; */
  }
}

 /* new css */
 .grid_img_div
 {
  display: grid;
  grid-template-columns: 100px calc(94% - 100px);
  grid-gap: 6%;
  gap: 6%;
  align-items: center;
 }
 .card_style_1
 {
   background-color: #fff !important;
   border:2px solid #fbbd18 !important;
   border:2px solid var(--themeclr) !important;
   /* border-radius: 20px !important; */
   box-shadow: 0 0 9px 3px #b5b5b5;
 }
/* input, textarea{
  background-color: #fff !important;
  border-radius: 5px !important;
}
.dark_theme input, .dark_theme textarea{
  background-color: #000 !important;

} */
 .blur {
  -webkit-filter: blur(4px);
          filter: blur(4px);
 }
 

.btn_grp_yel a
{
background-color: #fbbd18;
background-color: var(--themeclr);
/* color:#000; */
border:none;
border-radius: 0px !important;
margin-right: 5px;
cursor: pointer;
border:1px solid #fbbd18;
border:1px solid var(--themeclr);
/* padding: 1px 7px !important; */
}
.btn_grp_yel a:hover
{
  background-color:transparent;
color:#fbbd18;
color:var(--themeclr);
border-color:#fbbd18;
border-color:var(--themeclr);
}
.badge-green
{
  background-color: transparent;
  border:1px solid #2b710f;
  color:#2b710f;
  /* border-radius: 25px !important; */
  position: relative;
  padding: 5px 10px !important;

}
.green_dot
{
  width:8px;
  height:8px;
  background-color: #2b710f;
  border-radius: 50%;
  content: "";
  position: absolute;
  top:6px;
}
.green_txt
{
  padding-left: 12px;
}
.badge-yellow-fill
{
  background-color: #fbbd18;
  background-color: var(--themeclr);
  border:1px solid #fbbd18;
  border:1px solid var(--themeclr);
  color:#512b0e;
  /* border-radius: 25px !important; */
  position: relative;
  font-weight: 500 !important;
  padding: 5px 10px !important;

}
.grid_img_div img
{
  max-width: 100px;
  max-height: 100px;
  border-radius: 50%;
}
.yellow_txt
{
  color: #fbbd18;
  color: var(--themeclr);
  font-weight: 700;
}
.yellow_txt_sm
{
  color: #fbbd18;
  color: var(--themeclr);
}
.font_20
{
  font-size: 20px;
}
.font_16
{
  font-size: 16px;
}
.font_18
{
  font-size: 18px;
}
.yellow_bar .progress-bar
{
  background-color: #fbbd18;
  background-color: var(--themeclr);
}
.yellow_bar
{
  background-color: #383937 !important;
  /* border-radius: 20px !important; */
  height: 8px !important;
}
.white_txt_sm
{
  color:#fff;
}

@media only screen and (min-width:992px)
{
  .whole_sec
{
  padding-top: 100px;
}
  .col-lg-6.col-lg-6-custom
  {
    flex: 0 0 47.5% !important;
    max-width: 47.5% !important;

  }
  .offset-lg-1-custom
  {
    margin-left: 5% !important;
  }
}

.round_img
{
  max-width: 100px;
    max-height: 100px;
    border-radius: 50%;
}
.font_35

{
  font-size: 35px !important;
}
.badge-green-big
{
  padding: 10px 20px !important;
  text-transform: uppercase !important;
  font-size: 16px !important;
}
.badge-green-big .green_txt
{
  text-transform: uppercase !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}
.badge-green-big .green_dot
{
top:14px !important;
}
.badge-yellow-fill-big
{
  padding: 10px 15px !important;
  font-size: 16px !important;
  font-weight: 500 !important;

}
.font-weight-800
{
  font-weight: 800 !important;
}
.font-weight-500
{
  font-weight: 500 !important;

}

.badge-green-fill
{
  background-color: #2b710f;
  border:1px solid #2b710f;
  color:#fff;
  /* border-radius: 25px !important; */
  position: relative;
  padding: 8px 20px !important;
  text-transform: uppercase;
}

.badge-red-fill {
  background-color: red;
  border: 1px solid red;
  color: #fff;
  /* border-radius: 25px !important; */
  position: relative;
  padding: 8px 20px !important;
  text-transform: uppercase;
}
.line_he_big
{
  line-height: 25px;
}


.get-started-btn-fill
{
  background-color: #fbbd18 !important;
  background-color: var(--themeclr) !important;
  /* border-radius: 30px !important; */
  border:1px solid #fbbd18 !important;
  border:1px solid var(--themeclr) !important;
  color: white;
  padding: 8px 25px;
    font-size: 14px !important;
    font-weight: 700;
    text-transform: uppercase !important;
}
.get-started-btn-fill:hover
{
  background:transparent !important;
    border: 1px solid #fbbd18 !important;
    border: 1px solid var(--themeclr) !important;
  color:#fbbd18;
  color:var(--themeclr);

}
.tab_div .nav-pills
{
border-bottom: 1px solid #161616;
}

.tab_div .nav-pills .nav-link.active,.tab_div .nav-pills .show>.nav-link,
.tab_div .nav-pills .nav-link:hover
{
background-color: #fbbd18;
background-color: var(--themeclr);
color: white;
font-size: 16px;
font-weight: 500;
text-transform: uppercase;
/* border-radius: 10px 10px 0px 0px !important; */
}
.tab_div .nav-pills .nav-link
{
  font-size: 16px;
font-weight: 500;
text-transform: uppercase;
color:#fff;
margin-right: 15px;
}

.table_style_1 th,.table_style_1 td
{
  border-color: #8B8DFE !important;
  color: white !important;
  align-items: center;
  vertical-align: middle !important;
}
.table_style_1 td:last-child
{
  text-align: right;
}
.table_style_1 th
{
  background-color: #161616 !important;

}
.table_style_2 th:last-child,
.table_style_2 td:last-child
{
  max-width: 120px !important;
  width: 120px !important;
}
.table_style_2 th:nth-child(3),
.table_style_2 td:nth-child(3)
{
  min-width: 200px !important;
  max-width: 200px !important;
}
.searc_style_1
{
  max-width: 200px;
  margin-left: auto;
  background-color: transparent !important;
  border: 1px solid #fbbd18 !important;
  border: 1px solid var(--themeclr) !important;
  /* border-radius: 20px !important; */
  color: #fbbd18 !important;
  color: var(--themeclr) !important;
  

}
.searc_style_1::-webkit-input-placeholder
{
  color: #fbbd18 !important;
  color: var(--themeclr) !important;
  font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;

}
.searc_style_1::placeholder
{
  color: #fbbd18 !important;
  color: var(--themeclr) !important;
  font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;

}
.top_heqad_sec
{
  position: relative;
}

@media only screen and (min-width:768px)
{
 
  .top_heqad_sec .searc_style_1
  {
  position: absolute;
  right:0px;
  top:25px;
  z-index: 2;
  }
}
@media only screen and (max-width:767px)
{
  .top_heqad_sec .searc_style_1
  {
  margin-bottom: 20px;
  margin-top: 20px;
  }
}


.coin_ul_desc
{
  text-align: left;
  padding-top: 20px;
  padding-bottom: 10px;

}

.coin_ul_desc li {
  list-style-type: none;
  display: inline-block;
  margin: 0px 20px 10px;
  text-align: left;
}
.coin_desc_li_one {
  font-size: 12px;
  color:#fbbd18;
  color:var(--themeclr);
}
.coin_ul_desc li .coin_name_title {
  padding-left: 0px;
  font-weight: 600;
    font-size: 20px;
}

@media (min-width: 576px)
{
  .modal-dialog.modal-dialog-lg {
    max-width: 660px;
    margin: 1.75rem auto;
}
}

.card_toek
{
  background-color: #1b1b1b !important;
  /* border-radius: 10px !important; */
  border:none !important;
}

.coin_name_title
{
  color:#fff !important;
}

.input-groups .input-group
{
  border:1px solid #fbbd18;
  border:1px solid var(--themeclr);
  /* border-radius: 5px !important; */

}

.input-groups .input-group input::-webkit-input-placeholder
{
  color:#fff !important;
}

.input-groups .input-group input::placeholder
{
  color:#fff !important;
}

.get-started-btn-fill.text-capitalize
{
  text-transform: capitalize !important;
}

.btn_row
{
  justify-content: space-between;
  display: flex;
}

.input-group input:focus{
  background-color: transparent !important;
  box-shadow: 0 0 0 0rem rgb(0 123 255 / 25%) !important;
}

.modal-open .modal
{
  padding-right: 0px !important;
}

.icon_black
{
  color:#fff;
  cursor: pointer;
}

.icon_black.active
{
  color:#fbbd18;
  color:var(--themeclr);
}

.dropdown_yelo
{
  background-color: transparent !important;
    /* border-radius: 30px !important; */
    border: 1px solid #fbbd18 !important;
    border: 1px solid var(--themeclr) !important;
    color: #fbbd18;
    color: var(--themeclr);
    padding: 7px 25px;
    font-size: 14px !important;
    font-weight: 700;
}

.dropdown_yelo .btn
{
  padding: 0px !important;
  background-color: transparent !important;
  border:none !important;
  color: #fbbd18 !important;
  color: var(--themeclr) !important;
  box-shadow: 0 0 0 0rem rgb(72 180 97 / 50%) !important;
  display: flex;
  align-items: center;

  justify-content: center;
  text-align: center;
  margin-left: auto;
  margin-right: auto;


}

.dropdown_yelo img
{
  max-width: 20px;
  margin-right: 5px;
}

.dd_meu_header
{
  background-color: #000000 !important;
  border: 1px solid #fbbd18 !important;
  border: 1px solid var(--themeclr) !important;
  color: #fbbd18 !important;
  color: var(--themeclr) !important;
  -webkit-transform: translate(-15px, 50px) !important;
          transform: translate(-15px, 50px) !important;
  /* border-radius: 20px !important; */
  overflow: hidden;


}
.dd_meu_header a
{
  color: #fbbd18 !important;
  color: var(--themeclr) !important;
  /* border-radius: 20px !important; */


}

.dd_meu_header a:hover
{
  background-color: #131415;
}

@media only screen and (min-width:576px) and (max-width:767px)
{
  .coimg_soon_bg
  {
    background-size: cover,75% !important;
  }
  .detail-list .get-started-btn,.detail-list .get-started-btn-fill
  {
padding-left: 20px !important;
padding-right: 20px !important;
  }

  .whole_sec
  {
    padding-top: 140px !important;
  }
  .nav_parnt.d-flex
  {
    display: block !important;
  }
  .nav_parnt_1,.nav_parnt_2
  {
    display: inline-flex !important;
  }
  
  .header_flex
  {
    flex-direction: column;
  }
  
  .nav_parnt
  {
    flex-direction: column;
    /* margin-top: 20px; */
  }
  .nav_parnt_2,  .nav_parnt_1
  {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }

}

/* LOADER CSS */
.logo_overlay
{
  min-height: 100vh;
    position: absolute;
    background-color: #000;
    opacity: 0.5;
    width: 100%;
  top:0px;
position: relative;
}

.logo_overlay:before
{
  content: "";
  background-image: url(/static/media/world234BSCM.aa51378a.png);
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0.20;
  left: 0;
  background-size: 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
}




#loader_div
{
  position: absolute;
  min-height: 100vh;
  left:50%;
}

.logo_ovelay
{
  opacity:.5;
  min-height:100vh;
  position:absolute;
  top:0px;
}
@-webkit-keyframes spin
{
  from{
    -webkit-transform:rotate(0);
            transform:rotate(0)
  }
  to{
    -webkit-transform:rotate(360deg);
            transform:rotate(360deg)
  }
}
@keyframes spin
{
  from{
    -webkit-transform:rotate(0);
            transform:rotate(0)
  }
  to{
    -webkit-transform:rotate(360deg);
            transform:rotate(360deg)
  }
}
.logo_load
{
  max-width:80px;
  position:absolute;
  top:calc(40% + 75px);
  left:calc(50%);
  -webkit-transform:translate(-50%,-50%);
          transform:translate(-50%,-50%);
  z-index:1000
}
@-webkit-keyframes loader-02{
  0%{
    -webkit-transform:rotate(0);
            transform:rotate(0)
  }
  100%{
    -webkit-transform:rotate(360deg);
            transform:rotate(360deg)}
  }
@keyframes loader-02{
  0%{
    -webkit-transform:rotate(0);
            transform:rotate(0)
  }
  100%{
    -webkit-transform:rotate(360deg);
            transform:rotate(360deg)}
  }
.spin_round
{
  -webkit-animation-name:spin;
          animation-name:spin;
  -webkit-animation-duration:5s;
          animation-duration:5s;
  -webkit-animation-iteration-count:infinite;
          animation-iteration-count:infinite;
  -webkit-animation-timing-function:linear;
          animation-timing-function:linear;
  width:150px;
  height:150px;
  border-radius:50%;
  border:.2em solid transparent;
  border-left-color:#fbbd18;
  border-left-color:var(--themeclr);
  border-right-color:#fbbd18;
  border-right-color:var(--themeclr);
  border-radius:50%;
  -webkit-animation:1s loader-02 linear infinite;
          animation:1s loader-02 linear infinite;
  position:absolute;
  top:40%;left:calc(50% - 75px);
  -webkit-transform:translate(-50%,-50%);
          transform:translate(-50%,-50%);
  z-index:1000;
  opacity:1
}
@keyframes spin{
  from
  {
    -webkit-transform:rotate(0);
            transform:rotate(0)
  }
  to{
    -webkit-transform:rotate(360deg);
            transform:rotate(360deg)
  }
}


body.overflow_hidden
{
  overflow: hidden;
}
/* END LOADER CSS */
/* LIGHT THEME CSS */
.light_theme,
.light_theme #root
{
  background-color: #fff !important;
}
.light_theme .dd_meu_header 
{
background-color: #FFF !important;
}
.light_theme .dd_meu_header a:hover {
  background-color: #f4f4f4;
}
.light_theme .text-white
{
  color:#313131 !important;
}
.light_theme .input-group input::-webkit-input-placeholder
{
  color:#313131 !important;

}
.light_theme .icon_black,
.light_theme .side-head-li,
.light_theme .sec-head,
.light_theme .white_txt_sm,
.light_theme .tab_div .nav-pills .nav-link,
.light_theme .coin_name_title,
.light_theme .input-group input,
.light_theme .input-group input::placeholder,
.light_theme .input-group-text.doll
{
  color:#313131 !important;

}
.light_theme .icon_black.active
{
  color: #fbbd18 !important;
  color: var(--themeclr) !important;

}
.light_theme .modal-dialog .modal-content
{
  background-color:#fff !important;
}
.light_theme .wallet-lists ul li:hover,
.light_theme .modal-dialog .modal-header,
.light_theme .table_style_1 th,
.light_theme .card_toek
{
  background-color: #f4f4f4 !important;
}
.light_theme .table_style_1 th,
.light_theme .table_style_1 td,
.light_theme .tab_div .nav-pills

{
border-color: #ccc !important;
}

.light_theme .logo_overlay
{
  background-color: #fff !important;
}
/* END LIGHT THEME CSS */


@media only screen and (min-width:768px) and (max-width:991px)
{
  .locing_days_h
  {
    min-height: 42px;
  }
  .coimg_soon_bg
  {
    background-size: cover,65% !important;
  }
  .whole_sec
  {
    padding-top: 140px !important;
  }
  .header_flex
  {
    flex-direction: column;
  }
  .nav_parnt.d-flex
  {
    display: block !important;
    margin-top: 20px;
  }
  .nav_parnt_1,.nav_parnt_2
  {
    display: inline-flex !important;
  }
 
}

@media only screen and (min-width:992px)
{
  .nav_parnt_1
  {
    display: inline-flex !important;
    margin-right: 1rem !important;
  }
}
#header
{
  position: fixed;
    width: 100% !important;
    background: #000;
    z-index: 3;
}


.light_theme #header
{
  background-color: #fff !important;
}



/* HEADER NEW CSS */
/* mobile navbar */
.mobile-nav-active .mobile-nav {
  opacity: 1;
  visibility: visible;
}
.mobile-nav {
  position: fixed;
  top: 61px;
  right: 0px;
  left: 15px;
  z-index: 1000;
  overflow-y: auto;
  transition: ease-in-out 0.2s;
  /* border-radius: 10px; */
  padding: 10px 0;
  opacity: 0;
  /* display: none; */
}

.light_theme .mobile-nav{
  background: #f4f4f4;
}
.mobile-nav{
  background: #212121;
  padding-top: 30px !important;
}

.mobile-nav-toggle i.clicked {
  color: #fff;
  font-size: 20px;
}
.mobile-social li a span {
  background: #e6e6e8;
}
button#dropdown-basic-1{
  color: #264663;
}
.mobile-nav.active
{
  opacity: 1;
  display: block;
}
.mobile-nav * {
  margin: 0;
  padding: 0;
  list-style: none;
}
.mobile-nav a {
  display: block;
  position: relative;
  
  outline: none;
}
.mobile-nav-toggle {
  /* position: fixed;
  top: 15px;
  right: 15px; */
  z-index: 1000;
  border: 0;
  background: none;
  font-size: 20px;
  transition: all 0.4s;
  outline: none !important;
  line-height: 1;
  cursor: pointer;
  text-align: right;
}
.icofont-close:before {
  content: "\eee4";
}
.mobile-nav-active .mobile-nav-toggle i {
  color: #fff;
}
#mobileLayer.active {
  width: 100%;
  height: 100%;
  z-index: 9997;
  top: 0;
  left: 0;
  position: fixed;
  background: transparent;
  overflow: hidden;
  transition: ease-in-out 0.2s;
}
@media (max-width: 991px)
{
  .get-started-btn
  {
    display: block !important;
    margin-left: auto;
    margin-right: auto;
  }
  .whole_sec
  {
    padding-top: 65px !important;
  }
  .nav_parnt.d-flex, .nav_parnt_1,.nav_parnt_2
  {
    display: block !important;
  }
  .nav_parnt_1 .dropdown_yelo 
  {
    display: block !important;
    margin-right: 0px !important;
    padding: 7px 25px;
    max-width: 200px !important;
    min-width: 200px !important;

    margin-left: auto !important;
    margin-right: auto !important;

  }
  .mobile-nav-toggle i {
    color: #fbbd18;
    color: var(--themeclr);
    /* margin-top: 8px; */
}
.mobile-nav-toggle i.clicked {
  color: #fbbd18;
  color: var(--themeclr);
  /* margin-top: 8px; */

}
header .get-started-btn
{
    max-width: 200px;
    min-width: 200px;
    padding: 8px 25px !important;
    margin-top: 20px;
}
}
#burger.clicked::before{
  content: "\2190" !important;
}











@media (max-width: 991px)
{
  .dd_meu_header
  {
    min-width: 200px !important;
    left: 15px !important;
  }
  .theme-btn
  {
    margin-top: 20px !important;
  }
  .nav_parnt
  {
    text-align: center;
  }
  .left_stye {
    opacity: 1;
    left: 0;
    transition: 1s;
    min-height: 100vh;
    overflow-y: auto;
}
}


header
{
  display: flex !important;
    justify-content: space-between;
    align-items: center;
}

.modal-backdrop,.modal
{
  z-index: 9999 !important;
}

#mobile_nav_item
{
  display: none !important;
}
#mobile_nav_item.left_stye
{
  display: block !important;

}
/* END HEADER CSS */

/* ADMIN CSS */
.input-groups .get-started-btn-fill
{
  /* border-radius: 4px !important; */
}

.inputs_switdch .input-group {
  justify-content: space-between;
}
.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 25px;
  top: 7px;
  left: 6px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider.round {
  border-radius: 20px;
}
.slider, .slider:before {
  position: absolute;
  transition: .4s;
}
.slider {
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #212121;
}
.slider.round:before {
  border-radius:50%;
}
.slider:before {
  content: "";
  height: 16px;
  width: 16px;
  left: 3px;
  bottom: 4.5px;
  background-color: #fbbd18;
  background-color: var(--themeclr);
}
input:checked+.slider {
  background-image: linear-gradient(
60deg,#fbbd18,#fbbd18);
  background-image: linear-gradient(
60deg,var(--themeclr),var(--themeclr));
}
input:checked+.slider:before {
  -webkit-transform: translateX(21px);
  transform: translateX(21px);
  background-color: #000;
}

.detail-list label
{
  color:#fff;
  margin-bottom: 15px;
  margin-top: 15px;

}

.inputs_switdch  label
{
  margin-bottom: unset;
  margin-top: unset;
}

.detail-list .get-started-btn-fill
{
text-transform: capitalize !important;
}

.light_theme .detail-list label
{
  color: #313131 !important;
}
.light_theme .slider
{
  background-color: #dfdcdc;
}
.light_theme input:checked+.slider:before
{
  background-color: #fff;
}

/* END ADMIN CSS */

/* COMING SOON CSS */
.coimg_soon_bg
{
  background-image: url(/static/media/coming_bg.8eb28076.png),url(/static/media/coimg_img_bg.cb9f21e1.png);
  background-size: cover,40%;
  background-position: top,center;
  background-repeat: no-repeat,no-repeat;
  min-height: 100vh;
  -webkit-filter: hue-rotate(173deg);
          filter: hue-rotate(173deg);
}

.coimg_soon_center img
{
  max-width: 200px;
}
.coimg_soon_center
{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 100px);
  padding-top:50px;
  padding-bottom:50px;


}

.coimg_soon_text
{
  font-weight: 700;
  font-size: 40px;
}
.coimg_soon_text_white
{
  color:#fff;
}
.coimg_soon_text_yellow
{
  color:#fbbd18;
  color:var(--themeclr);
}
.light_theme .coimg_soon_text_white
{
  color:#313131;
}
.coing_soon_img
{
  position: relative;
  /* min-width: 80%; */
  /* min-height: 400px; */
}
.coing_soon_img
{
  /* content: ""; */
  background-image: url(/static/media/coimg_img_bg.cb9f21e1.png);
  background-size: cover;
  background-position: top;
  /* position: absolute;
  top: -135px;
    width: 400px;
    height: 400px;
    left: -50%; */
}

@media only screen and (min-width:992px) and (max-width:1199px)
{
  .coimg_soon_bg
  {
    background-size: cover,55% !important;
  }
}

/* END COMING SOON CSS */


/* CRAETE CSS */
.img_card_outer
{
  max-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  min-height: 200px;
}
.img_card_outer img
{
  object-fit: contain;
  max-height: 125px;

}

.card_img_craete .card-footer{
  background-color: #fbbd18 !important;
  background-color: var(--themeclr) !important;
    color: #23170a !important;
    /* border-radius: 0px 0px 18px 18px !important; */
    font-weight: 700;
    font-size: 16px !important;
}

a{
  outline-offset: 0px !important;
  outline: -webkit-focus-ring-color auto 0px !important;
}
.font_25
{
  font-size: 25px !important;
}
.input_desc_sm
{
  color:#fff;
}
.yelow_desc_blk
{
  color:#8f8f8f !important;
  font-size: 14px !important;
  padding-left: 10px;
}
.swithch_inline .inputs_switdch
{
  max-width: 62px !important;
}
.toggle_label
{
  display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
}
.datepicker_input input
{
  /* width: 100%; */
  width: calc(100% + 60px) !important;
  /* width: calc(100% - 116px) !important; */

  outline: #fbbd18 auto 0px !important;

  outline: var(--themeclr) auto 0px !important;
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range,
.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected
{
  background-color: #fbbd18 !important;
  background-color: var(--themeclr) !important;
  color: #000000 !important;
}
.date_inoput_grps
{
  display: flex;
}
/* .date_inoput_grps .input-group,.date_inoput_grps input,
.date_inoput_grps .react-datepicker__input-container
{
  width: calc(100% - 60px) !important;
} */
.date_inoput_grps .input-group
{
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;

}
.date_inoput_grps .input-group-append,.date_inoput_grps .input-group-append button
{
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;

}
.cur_pointer
{
cursor: pointer;  
}

.note_desc p
{
  color: #8f8f8f !important;
    font-size: 14px !important;
}

.text_are_grp textarea
{
  background: transparent;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: none;
    /* border-radius: 0px; */
    color: #fff !important;
    resize: none !important;
    outline-offset: 0px !important;
    outline: -webkit-focus-ring-color auto 0px !important;
    width: 100% !important;
}
.swithch_inline .input-group
{
border:none !important;
}
.inputs_switdch
{
  margin-right: 10px;
}
.light_theme .input_desc_sm,
.light_theme .text_are_grp textarea
{
  color:#000 !important;
}

/* END CREATE CSS */

.logo_img_sm
{
  max-width: 250px !important;
}
  /* .....v...... */
  .countdown div span {
    display: inline-block;
    font-size: 20px;
    color: white;
    margin-bottom: 10px;
    text-align: center;
    /* border-radius: 5px; */
    border: 2px solid #fbbd18;
    border: 2px solid var(--themeclr);
    padding: 0;
  position: relative;
}
.countdown{
  /* margin-left: 15px; */
  margin-right: 12px;
}
.countdown div >span {
  flex: 1 1;
}
.countdown div span:hover{
  color:#fbbd18;
  color:var(--themeclr);
}
.countdown div span span{
  border: none;
}
.countdown div {
  display: flex;
  grid-gap: 4%;
  gap: 4%;
  /* justify-content: center; */
  /* width: 70%; */
  /* margin: auto; */

  font-size: 12px;
  
  font-weight: 700;
}
.countbtn.countinfo  .countdown div {
  color:#2f800b
}
.countbtn.countup  .countdown div {
  color:#fbbd18;
  color:var(--themeclr);
}
.countdown div span span {
  display: block;
  font-size: 15px;
  background: #fbbd18;
  background: var(--themeclr);
  margin-bottom: 0;
  /* border-radius: 0; */
  line-height: 1.3;
}

.datepicker_input input {
  box-shadow: 0 0 0 0 rgba(0, 123, 255, 0) !important;
  outline: 0px !important;
}

/* .salecount .badge.upbtn {
  padding: 14px 19px !important;
  font-size: 15px;
}
.salecount span.green_dot {
  margin-top: 12px !important;
} */
  .infobtn span {
    color: #2f800b !important;
}
.infobtn .green_dot {
  background: #2f800b;
}
.infobtn  {
  border-color: #2f800b;
}

.dangerbtn span {
  color: red !important;
}
.dangerbtn .green_dot {
background: red;
}
.dangerbtn  {
border-color: red;
}


.upbtn span {
  color: #b3881f !important;
}
.upbtn .green_dot {
background: #b3881f;
}
.upbtn  {
border-color: #b3881f;
}

.countbtn span.green_txt {
  display: inline-block;
}
.countbtn span.green_dot {
  display: inline-block;
  margin-top: 2px;
}

.countbtn .badge {
  font-weight: 400;
}

.badge {
  font-size: 100% !important;
}

button[disabled] {
  opacity: 0.4;
}
button[disabled]:hover {
  background: transparent;
  opacity: 0.4;
  color: #fbbd18;
  color: var(--themeclr);
}

.browsebtn  {
 position: relative;
 width: 100px;
}

.browsebtn input {
  width: 100px;
  cursor: pointer;
}
.browsebtn label {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
  background: #fbbd18;
  background: var(--themeclr);
  width: 100px;
  /* border-radius: 30px; */
  text-align: center;
  line-height: 3;
  color: black;
  pointer-events: none;
  height: 40px;
  font-weight: 700;
}




  .ribbon{
    content: "";
    height: 22px;
    background: #154c60;
    position: absolute;
    right: -31px;
    width: 132px;
    top: 19px;
    z-index: 2;
    display: block;
    left: 0;
    margin-left: auto;
    -webkit-transform: rotate(38deg);
            transform: rotate(38deg);
    text-align: center;
    color:#fff;
  }
  .ribbox{
    overflow: hidden;
position: relative;
  }
  .form-control:disabled, .form-control[readonly]{
    background-color:transparent !important;
  }
  
    .modalbtn .inputs input {
      border: 1px solid #fbbd18;
      border: 1px solid var(--themeclr);
      /* border-radius: 5px; */
      margin-top: 5px;
    }
    .modalbtn .badge-yellow-fill-big {
      padding: 5px 15px 8px 15px !important;
    }
  a{
    outline: none !important
  }


  /* loader */
  .themeloader{
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
      
  }
  /* Creating the dots */
  .themeloader span{
    height: 15px;
    width: 15px;
    margin-right: 10px;
    border-radius: 50%;
    background-color: #fbbd18;
    background-color: var(--themeclr);
    -webkit-animation: loading 1s linear infinite;
            animation: loading 1s linear infinite;
  }
  /* Creating the loading animation*/
  @-webkit-keyframes loading {
    0%{
     -webkit-transform: translateX(0);
             transform: translateX(0);
    }
    25%{
     -webkit-transform: translateX(15px);
             transform: translateX(15px);
    }
    50%{
     -webkit-transform: translateX(-15px);
             transform: translateX(-15px);
    }
    100%{
     -webkit-transform: translateX(0);
             transform: translateX(0);
    }
      
  }
  @keyframes loading {
    0%{
     -webkit-transform: translateX(0);
             transform: translateX(0);
    }
    25%{
     -webkit-transform: translateX(15px);
             transform: translateX(15px);
    }
    50%{
     -webkit-transform: translateX(-15px);
             transform: translateX(-15px);
    }
    100%{
     -webkit-transform: translateX(0);
             transform: translateX(0);
    }
      
  }
   .themeloader span:nth-child(1){
   -webkit-animation-delay: 0.1s;
           animation-delay: 0.1s;
 }
   .themeloader span:nth-child(2){
   -webkit-animation-delay: 0.2s;
           animation-delay: 0.2s;
 }
   .themeloader span:nth-child(3){
   -webkit-animation-delay: 0.3s;
           animation-delay: 0.3s;
 }
   .themeloader span:nth-child(4){
   -webkit-animation-delay: 0.4s;
           animation-delay: 0.4s;
 }
   .themeloader span:nth-child(5){
   -webkit-animation-delay: 0.5s;
           animation-delay: 0.5s;
 }
#header {
  box-shadow: 0 0 10px 2px #e8e8e8;
}

.browsebtn.width ,.browsebtn.width label{
  width: 150px;
  height: 45px;
}

.browsebtn.width .get-started-btn-fill{
  width: 150px;
}
.dark_theme .card_style_1 {
  background-color: black !important;
  box-shadow: 0 0 16px 5px #8B8DFE;
}
.dark_theme #header{
  box-shadow: 0 0 10px 2px #222222;
}
html,body{
  font-family: 'Kanit', sans-serif !important;
  font-size: 14px;
}
a{
    text-decoration: none !important;
}
body{
  background-color: #000 !important;
}
.footer_bg_purple
{
    /* background-color: #020202; */
   
    padding: 30px 0px 30px;
    /* border-top:6px solid #fbbe18; */
}
/* .footer

{
    background: url(../images/footer.png) no-repeat scroll 0 0;
    background-size: cover;
    background-position: top;
} */
.footer_ul
{
    padding: 0;
    list-style-type: none;
}
.footer_ul li,.footer_ul li a{
    color:#adadad;
    margin-bottom: 20px;
    font-size: 30px;
}
.subscribe_sec p
{
    color:#adadad;
    margin-bottom: 20px;
    font-size: 18px;
    text-transform: uppercase;
}

.subscribe_sec .form-control
{
    background-color: #211a79;
    border: 1px solid #211a79;
    margin-bottom: 15px;
    border-radius: 5px;
    color:#fff;
}

.subscribe_sec .form-control:focus
{
    background-color: #211a79;
    border: 1px solid #211a79;
    box-shadow: 0 0 0 0rem rgb(0 123 255 / 25%);
}
.subscribe_sec a{
    float: right;
    margin-bottom: 15px;
}
.subscribe_sec
{
    margin-top: 75px;
}
.footer_sec
{
    border-bottom: 1px solid #2f25a4;
}
.footer_text
{
    font-size: 14px !important;
    color: #adadad;
    font-weight: 500;
    /* text-transform: uppercase; */
}
.footer_ul a span
{
    position: relative;
}
.footer_ul a:hover
{
    color: #8b298b !important;
}


.footer_ul a:hover span:after, .footer_ul a.active span:after {
    width: 100%;
}
.subscribe_sec a{
    position: relative;
    top:0px;
    transition: all .5s;
}
.subscribe_sec a:hover{
    top:-3px;
}
.footer_ul a img
{
    position: relative;
    top:0px;
    transition: all .5s; 
}
.footer_ul a:hover img{
    top:-3px;

}
.text_yelw
{
    color:#FFF !important;
}
.subscribe_sec .form-control::-webkit-input-placeholder
{
 color:#fff;   
}
.subscribe_sec .form-control::placeholder
{
 color:#fff;   
}

/* light theme */
body.light_theme .footer_bg_purple
{
    /* background-image: linear-gradient(45deg,#fff 6%, #fff 59%, #fff); */
    /* background-image: linear-gradient(45deg,#d1cccc 6%, #fff 59%, #d1c0c0); */
    /* background-image: linear-gradient(45deg,#d1cccc 6%, #fff 59%, #261c991f); */
    /* background-image: linear-gradient(45deg,#f7efef 6%, #fff 59%, #261c9908); */
    /* background-image: linear-gradient(45deg,#d1cccc 6%, #fff 59%, #261c991f); */
    /* background-image: linear-gradient(45deg,#d1cccce3 6%, #fff 59%, #a9a3f273); */
    background-image: linear-gradient(45deg,#d1cccc 6%, #fff 59%, #261c991f);
}
body.light_theme .footer_ul li,body.light_theme .footer_ul li a,body.light_theme .text_yelw
{
    color: #fff !important;
}
body.light_theme .subscribe_sec .form-control::-webkit-input-placeholder
{
    color: #131f32;
}
body.light_theme .subscribe_sec p,body.light_theme .subscribe_sec .form-control::placeholder
{
    color: #131f32;
}
body.light_theme .footer_ul a:hover {
    color: #060606 !important;
}
body.light_theme .subscribe_sec .form-control,body.light_theme .subscribe_sec .form-control:focus
{
    background-color: #ebeaf4;
    border-color: #607d8b;
}
body.light_theme .footer_sec
{
border-color: #ded8d8 !important;
}
body.light_theme .subscribe_sec .form-control, body.light_theme .subscribe_sec .form-control:focus
{
    background-color: #ebeaf4;
    border-color: #ded8d8;
}
.footer_ul_hor
 {
     text-align: center;
margin-bottom: 0px !important;

 }
 .footer_ul_hor li
 {
display: inline-block;
margin-top: 20px;
margin-bottom: 0px !important;
 }
